import { useLocation } from "react-router-dom";

function HttpsRedirect() {
  const location = useLocation();

  if (
    window.location.protocol !== "https:" &&
    !window.location.hostname.includes("dev") &&
    window.location.hostname !== "localhost"
  ) {
    window.location.replace(
      `https://${window.location.hostname}${location.pathname}`
    );
  }

  return null;
}

export default HttpsRedirect;
