import React, { FC } from "react";
import "./TermBox.css";
import { Term } from "../../common/types";
interface Props {
    term: Term;
    appName: string;
    id: string;
}

const TermBox: FC<Props> = (props) => {
    const { term, appName, id } = props;

    let style = 'termBox';

    if (term.status == 'green') {
        style += ' termStatusGreen';
    } else if (term.status == 'red') {
        style += ' termStatusRed';
    }

    return (
        <>
        <div className={style}>
            <div className="termDetails">
                <b>{term.term}: </b>{term.value}
            </div>
            {/* { term.status && (<div><span className={style}></span></div>)} */}
        </div>
        </>
    )

}

export default TermBox;