import React, { FC } from "react";
import Markdown from 'react-markdown';
import "./Summary.css"

interface Props {
    summary: string;
    shouldHide: boolean;
}

const Summary: FC<Props> = (props) => {
    const { summary, shouldHide } = props;


    return (
        <>
            { !shouldHide && (<Markdown className={"summaryText"}>{summary}</Markdown>) }
        </>
    )

}

export default Summary;
