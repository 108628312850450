import React, { FC, useEffect, useState } from "react";
import "./MessageBox.css";
import { Message } from "../../common/types";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CitationDesc, CitationNumber } from "./styled";
import { demos } from "common/demoNames";
interface Props {
  message: Message;
  appName: string;
  id: string;
}

const MessageBox: FC<Props> = (props) => {
  const { message, appName, id } = props;
  const messageClass = message.isBot ? "bot-message" : "user-message";
  const [contentTextList, setContentTextList] = useState<string[]>();
  const [parts, setParts] = useState<RegExpMatchArray | null>();
  const [selectedCitation, setSelectedCitation] = useState<
    string | undefined
  >();
  const [selectedReference, setSelectedReference] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    setParts(message.content.match(/\[\d\]/g));
    setContentTextList(message.content.split(/\[\d\]/g));
  }, [message]);

  useEffect(() => {
    if (selectedReference)
      setSelectedCitation(
        message?.sources[selectedReference - 1]?.page_content
      );
  }, [message.sources, selectedReference]);

  const getChatImage = () => {
    if (message.isBot) {
      return require("images/chat-bot-image.jpeg");
    } else {
      return require("images/profile-pic.jpg");
    }
  };

  const displayName = "User";

  const getReferenceNumber = (index: number) => {
    try {
      // Demo only
      if (parts != null) {
        const item = parts[index];
        return parseInt(item.replace("[", "").replace("]", ""));
      }
    } catch (error) {
      return -1;
    }
    return -1;
  };

  const handleSelectCitation = (reference: number | undefined) => {
    setSelectedReference(reference);
    if (reference !== -1) {
      const len = message.sources.length;
      if (reference && reference > len - 1) {
        setSelectedCitation(
          reference ? message.sources[len - 1].page_content : undefined
        );
      } else {
        setSelectedCitation(
          reference ? message.sources[reference - 1].page_content : undefined
        );
      }
    }
  };

  return (
    <div
      data-cy={`${messageClass}`}
      className={`conversation-parent-container ${messageClass}`}
    >
      <div className="conversation-container">
        <img className="profile-image" src={getChatImage()} alt="" />

        <div className="message-container" id="message-container">
          <p>
            {" "}
            <b>{message.isBot ? appName : displayName}</b>
          </p>
          <div id="chat-container">
            <div className="message-content">
              {message.content === "..." ? (
                <img
                  style={{ width: 100, height: 50 }}
                  alt=""
                  src={require("gifs/text-loading.gif")}
                />
              ) : (
                <div>
                  {contentTextList?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.includes("References:") && (
                        <>
                          <br></br>
                          <br></br>
                        </>
                      )}
                      {item[0] === "." || item[0] === ","
                        ? item.substring(1)
                        : item}
                      {getReferenceNumber(index) !== -1 && (
                        <CitationNumber
                          className= {demos[id]?.CitationNumberClass ? demos[id]?.CitationNumberClass : "text-green-700 bg-green-400"}
                          onClick={() => {
                            handleSelectCitation(getReferenceNumber(index));
                          }}
                        >
                          {getReferenceNumber(index)}
                        </CitationNumber>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          </div>

          {selectedReference && (
            <CitationDesc
              className= {demos[id]?.CitationDescClass ? demos[id]?.CitationDescClass : "mt-4 p-4 shadow-sm bg-white text-green-700 pt-4"} >
              <AiOutlineCloseCircle
                onClick={() => {
                  handleSelectCitation(undefined);
                }}
              />
              {selectedCitation ? (
                selectedCitation
              ) : (
                <img
                  className="ml-auto mr-auto w-20 h-20"
                  src={require("../../gifs/citationsLoading.gif")}
                  alt=""
                />
              )}
            </CitationDesc>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
