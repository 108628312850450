import React, { useState, useRef } from "react";
import "./Chatroom.css";
import MessageList from "./MessageList";
import { DateTimeFormatOptions } from "intl";
import { Message } from "../common/types";
import { ReactComponent as SendIcon } from "../icons/sendIcon.svg";
import { SubmitButton } from "./ChatRoomStyled";
import { demos } from "common/demoNames";

import LZString from "lz-string";
import { MAX_CONTEXT_LENGTH } from "constants/chatroom";

const options: DateTimeFormatOptions = {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: false,
};

interface ChatroomProps {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;

  collection: String;
  appName: string;
  id: string;
  personality: string;
}
let backendURL =
  process.env.REACT_APP_BACKEND_URL || "http://34.36.104.209/api";
const Chatroom: React.FC<ChatroomProps> = ({
  messages,
  setMessages,

  collection,
  appName,
  id,
  personality,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [currentMessages, setCurrentMessages] = useState<Message[]>([]);
  const messageEndRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!inputValue) return;
    const now: Date = new Date();
    const timeString: string = now.toLocaleString("en-US", options);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        content: timeString,
        isBot: false,
        isURL: false,
        isTimestamp: true,
        sources: [],
      },
      {
        content: inputValue,
        isBot: false,
        isURL: false,
        isTimestamp: false,
        sources: [],
      },
      {
        content: "...",
        isBot: true,
        isURL: false,
        isTimestamp: false,
        sources: [],
      }, // Add a loading message
    ]);
    let tempInputValue: string = inputValue;
    setInputValue("");
    setCurrentMessages((prevMessages) => [
      ...prevMessages,
      {
        content: timeString,
        isBot: false,
        isURL: false,
        isTimestamp: true,
        sources: [],
      },
      {
        content: inputValue,
        isBot: false,
        isURL: false,
        isTimestamp: false,
        sources: [],
      },
      {
        content: "...",
        isBot: true,
        isURL: false,
        isTimestamp: false,
        sources: [],
      }, // Add a loading message
    ]);

    let query_params = {
      collection: collection,
      question: tempInputValue,
      personality: personality,
      history: currentMessages.map((message) => ({ ...message, sources: [] })),
    };

    let compressedParams = LZString.compressToEncodedURIComponent(
      JSON.stringify(query_params)
    );

    if (compressedParams.length > MAX_CONTEXT_LENGTH) {
      let updatedHistoryContext =
        query_params.history[query_params.history.length - 1];
      query_params = {
        collection: collection,
        question: tempInputValue,
        personality: personality,
        history: [updatedHistoryContext],
      };
      compressedParams = LZString.compressToEncodedURIComponent(
        JSON.stringify(query_params)
      );
      setCurrentMessages((prevMessages) => [
        ...prevMessages.slice(prevMessages.length - 1),
      ]);
      // setMessages((prevMessages) => [prevMessages[prevMessages.length - 1]]);
    }
    const evtSource = new EventSource(
      encodeURI(`${backendURL}/query_chat_stream?json_obj=` + compressedParams)
    );

    let content = "";
    let source_documents = [
      {
        metadata: "",
        page_content: "",
      },
    ];
    try {
      evtSource.addEventListener("new_message", function (event) {
        const data = JSON.parse(event.data);
        content = content + data.data;
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1), // Remove the loading message
          {
            content: content,
            isBot: true,
            isURL: false,
            isTimestamp: false,
            sources: source_documents,
          },
        ]);
        setCurrentMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          {
            content: content,
            isBot: true,
            isURL: false,
            isTimestamp: false,
            sources: source_documents,
          },
        ]);
      });
      evtSource.addEventListener("end_event", function (event) {
        source_documents = JSON.parse(event.data).sources;
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessageIndex = updatedMessages.length - 1;
          const lastMessage = updatedMessages[lastMessageIndex];
          lastMessage.sources = source_documents;
          return updatedMessages;
        });
        setCurrentMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessageIndex = updatedMessages.length - 1;
          const lastMessage = updatedMessages[lastMessageIndex];
          lastMessage.sources = source_documents;
          return updatedMessages;
        });
        evtSource.close();
      });
    } catch (error) {
      console.error("Error sending message:", error);
      evtSource.close();
    }
  };

  // Scrolls to the bottom of the message list
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Scrolls to the bottom on initial render and whenever messages change
  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chatroom-container">
      <div className="message-list-wrapper">
        <MessageList appName={appName} messages={messages} id={id}/>
        <div ref={messageEndRef} />
      </div>
      <form className="input-form" onSubmit={handleSubmit}>
        <input
          className="messageInput"
          type="text"
          placeholder="Message"
          value={inputValue}
          onChange={handleInputChange}
        />
        <SubmitButton bgColor={demos[id].send_bg_color}>
          <div className="send-icon-wrapper">
            <SendIcon />
          </div>
        </SubmitButton>
      </form>
    </div>
  );
};

export default Chatroom;
