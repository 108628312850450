import styled from "styled-components";

interface Props {
  bgColor?: string;
}

export const SubmitButton = styled.button.attrs({
  type: "submit",
})<Props>`
  background-color: ${(props) => props.bgColor || "#02786e"};
  border-radius: 10px;
  height: 81px;
  width: 150px;
  cursor: pointer;
  color: white;
  border: none;
  cursor: pointer;
`;
