import React, {FC, useEffect, useState } from 'react';
import Summary from "./Summary";
import LZString from "lz-string";
import "./summaryBox.css"

interface SummaryBoxProps {
    summary: string;
    setSummary: React.Dispatch<React.SetStateAction<string>>;
    appName: string;
    id: string;
    collection: string;
    personality: string;
}

let backendURL = 
    process.env.REACT_APP_BACKEND_URL || "http://34.36.104.209/api";

const SummaryBox: FC<SummaryBoxProps> = ({
    summary,
    setSummary,
    appName,
    id,
    collection,
    personality
}) => {
    const [shouldHide, setShouldHide] = useState(true);
    const [summaryLoading, setSummaryLoading] = useState(true);
    useEffect(() => {
        const fetchSummary = async() => {
            let query_params = {
                collection: collection,
                personality: personality,
            };

            let compressedParams = LZString.compressToEncodedURIComponent(
                JSON.stringify(query_params)
            );

            const fetchedSummary = await (
                await fetch (`${backendURL}/get_summary?jsonobj=` + compressedParams)
            ).json();

            setSummary(fetchedSummary);
            setShouldHide(false);
            setSummaryLoading(false);
        }

        fetchSummary();
    }, []);

    function handleHideShowClick() {
        setShouldHide( !shouldHide);
    }

    return (
        <>
            <div className="summaryButton">
                <button onClick={handleHideShowClick} id="summaryController">{!shouldHide ? "Hide Summary" : "Show Summary"}</button>
            </div>
            <div className="summary-container" id="summaryContent">
                {summaryLoading ? "Summary is loading..." : <Summary summary={summary} shouldHide={shouldHide} />}
            </div>
        </>
    );
}

export default SummaryBox;
