import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useParams } from "react-router-dom";

interface AuthProps {
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const Auth: React.FC<AuthProps> = ({ setAuthenticated }) => {
  const MySwal = withReactContent(Swal);
  let backendURL =
    process.env.REACT_APP_BACKEND_URL || "http://34.36.104.209/api";
  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    // Add redirection for nylgenai domain
    if (
      (window.location.origin === "https://nylgenai.com" ||
        window.location.origin === "https://nylgenai.com" ||
        window.location.origin === "https://www.nylgenai.com" ||
        window.location.origin === "https://www.nylgenai.com") &&
      id === undefined
    ) {
      return navigate("/auth/new-york-life");
    }
    if (id === undefined) {
      setAuthenticated(true);
      return navigate("/");
    }
    MySwal.fire({
      html: (
        <>
          <h3 style={{ fontSize: 20, textDecoration: "underline" }}>
            Disclaimer
          </h3>
          <br />
          <ol>
            <li style={{ fontSize: 16 }}>
              Please use this application for demo purposes only.
            </li>
            <li style={{ fontSize: 16 }}>
              Avoid uploading sensitive information, use only public data.
            </li>
          </ol>
          <br />
          <h1 style={{ fontSize: 28, fontWeight: "bold" }}>
            Please enter the password for this app
          </h1>
        </>
      ),
      allowOutsideClick: false,
      input: "password",
      inputPlaceholder: "Enter the password",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
    }).then(async (result) => {
      try {
        const res = await axios.post(`${backendURL}/validate-passphrase`, {
          demo: id,
          pass_phrase: result.value,
        });
        if (res.data.is_valid) {
          if (id) {
            setAuthenticated(true);
            navigate(`/${id}`);
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Incorrect password!",
            showConfirmButton: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        navigate("/page-not-found");
      }
    });
  }, [MySwal, backendURL, id, navigate, setAuthenticated]);
  return <></>;
};

export default Auth;
