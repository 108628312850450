import React, { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineExclamationCircle } from "react-icons/ai";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const DisclaimerModal: FC<IProps> = (props) => {
  const { open, setOpen } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                    <AiOutlineExclamationCircle
                      className="h-6 w-6 text-yellow-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base text-center  font-semibold leading-6 text-gray-900"
                    >
                      Instructions
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">
                        <ul>
                          <li>1. Upload PDFs</li>
                          <li>2. Wait for processing</li>
                          <li>3. Ask Questions</li>
                        </ul>
                      </div>
                    </div>

                    <Dialog.Title
                      as="h3"
                      className="text-base text-center  font-semibold leading-6 text-gray-900"
                    >
                      Disclaimer
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">
                        <ul>
                          <li>1. This app is for DEMO purposes only</li>
                          <li>2. DO NOT USE private or sensitive data</li>
                          <li>3. Max upload size is 100MB</li>
                          <li>
                            4. Original PDFs work best
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="mt-2">
                      <div className="text-sm text-gray-500">
                        Reach out to Giulio Batterman (
                        <a href="mailto:batterman.giulio@bcg">batterman.giulio@bcg</a>) for
                        Demo requests, questions, or support
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setOpen(false)}
                  >
                    Continue
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DisclaimerModal;
