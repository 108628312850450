import React, { useState, ReactNode, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import TagManager from "react-gtm-module";
import { HomePage } from "./pages";
import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";
import HttpsRedirect from "./HttpsRedirect";

// TODO: Use env var
const tagManagerArgs = {
  gtmId: "G-ZR9P2RT1C7",
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const RedirectMoi = () => {
    useEffect(() => {
      window.location.replace("http://34.70.108.70");
    }, []);
  
    return null;
  };

  return (
    <Router>
      <HttpsRedirect />
      <div>
        <Routes>
          <Route
            path="/:id?"
            element={
              <RequireAuth authenticated={authenticated}>
                <HomePage />
              </RequireAuth>
            }
          />
          <Route
            path="/auth/:id?"
            element={<Auth setAuthenticated={setAuthenticated} />}
          />
          <Route path="/moi" element={<RedirectMoi />} />
          <Route path="/page-not-found" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

function RequireAuth({
  children,
  authenticated,
}: {
  children: ReactNode;
  authenticated: boolean;
}) {
  let location = useLocation();
  if (!authenticated) {
    return (
      <Navigate
        to={`/auth${location.pathname}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
}
