import axios from "axios";

axios.interceptors.request.use(
  async (config) => {
    try {
    } catch (error) {}
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
