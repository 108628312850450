import React from "react";
import "./Upload.css";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function generateRandomString(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
interface UploadProps {
  setCollection: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<Boolean>>;
  setUploadedFile: React.Dispatch<React.SetStateAction<string>>;
  id: string;
}
const MySwal = withReactContent(Swal);

const Upload: React.FC<UploadProps> = ({ setCollection, setLoading, setUploadedFile, id }) => {
  let backendURL =
    process.env.REACT_APP_BACKEND_URL || "http://34.36.104.209/api";

  const uploadOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    let size = 0;
    if (files !== null)
      for (let i = 0; i < files.length; i++) {
        size += files[i].size;
      }
    if (size > 104857600) {
      MySwal.fire({
        icon: "error",
        title: "File size too large",
        text: "File size cannot be more than 100 mb",
        showConfirmButton: false,
        timer: 4000,
      });
      e.target.value = "";
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    let formData = new FormData();
    let files = (document.querySelector("#file") as HTMLInputElement)?.files;
    if (files !== null) {
      setUploadedFile(URL.createObjectURL(files[0]));
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }
    if (id !== "") formData.append("app_title", id);
    else formData.append("app_title", generateRandomString(6));
    await axios
      .post(`${backendURL}/ingest`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 600000,
      })
      .then((res) => {
        setCollection(res.data.collection_name);
        MySwal.fire({
          icon: "success",
          title: "Files Uploaded!",
          text: "Ready to answer your questions!",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Some error occured, please try again later",
          showConfirmButton: false,
          timer: 4000,
        });
      });
    setLoading(false);
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div className="upload-section">
        <label className="drop-container">
          <span className="drop-title">Drop files here</span>
          or
          <input
            onChange={(e) => uploadOnChange(e)}
            id="file"
            type="file"
            name="files"
            accept="application/pdf, audio/wav, audio/mp3"
            multiple
            required
          />
        </label>
        <button className="submit-button">Submit</button>
      </div>
    </form>
  );
};

export default Upload;
