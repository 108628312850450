import { FC } from "react";
import "./TimestampBox.css";

interface Props {
  timestamp: string;
}

const TimestampBox: FC<Props> = ({ timestamp }) => {
  return <small className={`message timestamp-message`}>{timestamp}</small>;
};

export default TimestampBox;
