import React, { FC } from "react";
import MessageBox from "./MessageBox";
import TimestampBox from "./TimestampBox";
import { Message } from "../common/types";

interface Props {
  messages: Message[];
  appName: string;
  id:string;
}

const MessageList: FC<Props> = ({ messages, appName,id }) => {
  return (
    <>
      {messages.map((message, index) => {
        if (message.isTimestamp) {
          return <TimestampBox key={index} timestamp={message.content} />;
        } else {
          return <MessageBox key={index} message={message} appName={appName} id={id} />;
        }
      })}
    </>
  );
};

export default MessageList;
