import React, { FC, useState, useEffect } from "react";
import TermBox from "./TermBox";
import { Term } from "common/types";
import "./termList.css";

interface TermListProps {
    terms: Term[];

    appName: string;
    id: string;
}

const TermList: FC<TermListProps> = ({ terms, appName, id}) => {
    return (
        <div className="termList">
            {terms.map((term,index) => {
                return <TermBox key={index} term={term} appName={appName} id={id} />;
            })}
        </div>
    )
}

export default TermList;