import NewYorkLifeLogo from "../images/New-York-Life-logo.png";
import DHLLogo from "../images/dhl-logo.png";
import StaplesLogo from "../images/staples_logo.png";
import OlympusLogo from "../images/olympus-logo.jpg";
import BCGLogo from "../images/bcg-logo.png";
import StraumannLogo from "../images/straumann-logo.png";
import HDILogo from "../images/hdi-logo.png";
import RTILogo from "../images/rti.png";
import FerreroLogo from "../images/Ferrero.png";
import VirginMediaO2Logo from "../images/virginmediao2.png";
import AXALogo from "../images/axa.png";
import ZoetisLogo from "../images/Zoetis.png";
import AramcoLogo from "../images/Saudi-Aramco-Logo.jpg";
import SanofiLogo from "../images/SanofiLogo.png";
import azurityLogo from "../images/azurityLogo.png";
import EthiconLogo from "../images/EthiconLogo.png";
import SouthwestBlueLogo from "../images/SouthwestBlueLogo.png";
import NovoNordiskLogo from "../images/novo_nordisk_logo.png";
import vyeptiLogo from "../images/vyepti.png";
import BCGXLogo from "../images/BCGXLogo.png";
import takedaLogo from "../images/takeda_logo.png";
import { DemosMap } from "../common/types";
import paddyLogo from "../images/paddy-power-premier-league.png"
import ExelonLogo from "../images/ExelonLogo.png";
import OmahaLogo from "../images/OmahaLogo.png"
import Bicester_Village_logo from "../images/google_maps_logo.png"
import procurement_logo from "../images/procurement.png"

export const demos: DemosMap = {
  "new-york-life": {
    title: "Assistant GPT",
    logo: NewYorkLifeLogo,
    sidebar_bg_color: "#1278C7",
    send_bg_color: "#1278C7",
    personality: "nyl",
  },
  "new-york-life-dev": {
    title: "Assistant GPT",
    logo: NewYorkLifeLogo,
    sidebar_bg_color: "#1278C7",
    send_bg_color: "#1278C7",
    personality: "nyl",
  },
  "new-york-life-audio": {
    title: "Assistant GPT",
    logo: NewYorkLifeLogo,
    sidebar_bg_color: "#1278C7",
    send_bg_color: "#1278C7",
    personality: "nyl",
  },
  zoetis: {
    title: "Assistant GPT",
    logo: ZoetisLogo,
    sidebar_bg_color: "#ff9447",
    send_bg_color: "#ff9447",
    personality: "zoetis",
    width: "300px",
  },
  sanofi: {
    title: "Assistant GPT",
    logo: SanofiLogo,
    sidebar_bg_color: "#5698e8",
    send_bg_color: "#5698e8",
    personality: "base",
    width: "300px",
  },
  azurity: {
    title: "Assistant GPT",
    logo: azurityLogo,
    sidebar_bg_color: "#5698e8",
    send_bg_color: "#5698e8",
    personality: "base",
    width: "300px",
  },
  "": {
    title: "GenAI Contract Workbench",
    logo: "",
    sidebar_bg_color: "#101828",
    send_bg_color: "#101828",
    personality: "base",
  },
  dhl: {
    title: "Assistant GPT",
    logo: DHLLogo,
    sidebar_bg_color: "#ffcc02",
    send_bg_color: "#ffcc02",
    personality: "base",
    width: "300px",
  },
  staples: {
    title: "Assistant GPT",

    logo: StaplesLogo,
    sidebar_bg_color: "#cc0100",
    send_bg_color: "#cc0100",
    personality: "base",
    width: "280px",
  },
  olympus: {
    title: "Assistant GPT",
    logo: OlympusLogo,
    sidebar_bg_color: "#09117a",
    send_bg_color: "#09117a",
    personality: "base",
    width: "300px",
  },
  bcg: {
    title: "Assistant GPT",
    logo: BCGLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "bcg",
    width: "250px",
  },
  bcgx: {
    title: "PlaybookGPT",
    logo: BCGXLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "bcgx",
    width: "250px",
  },
  straumann: {
    title: "Assistant GPT",
    logo: StraumannLogo,
    sidebar_bg_color: "#46b98c",
    send_bg_color: "#46b98c",
    personality: "base",
    width: "250px",
  },
  hdi: {
    title: "Assistant GPT",
    logo: HDILogo,
    sidebar_bg_color: "#2a6530",
    send_bg_color: "#2a6530",
    personality: "base",
  },
  rti: {
    title: "Assistant GPT",
    logo: RTILogo,
    sidebar_bg_color: "#244f99",
    send_bg_color: "#244f99",
    personality: "base",
    width: "200px",
  },
  ferrero: {
    title: "Assistant GPT",
    logo: FerreroLogo,
    sidebar_bg_color: "#e2ac51",
    send_bg_color: "#e2ac51",
    personality: "ferrero",
    width: "200px",
  },
  virginmediao2: {
    title: "Assistant GPT",
    logo: VirginMediaO2Logo,
    sidebar_bg_color: "#222221",
    send_bg_color: "#222221",
    personality: "base",
    width: "200px",
  },
  axa: {
    title: "Underwriting Hub",
    logo: AXALogo,
    sidebar_bg_color: "#999",
    send_bg_color: "#999",
    personality: "base",
    width: "200px",
  },
  pipe: {
    title: "Assistant GPT",
    logo: BCGLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "base",
    width: "250px",
  },
  aramco: {
    title: "Assistant GPT",
    logo: AramcoLogo,
    sidebar_bg_color: "#00A3E0",
    send_bg_color: "#00A3E0",
    personality: "aramco",
    width: "250px",
  },
  ethicon: {
    title: "Assistant GPT",
    logo: EthiconLogo,
    sidebar_bg_color: "#c8102e",
    send_bg_color: "#c8102e",
    personality: "ethicon",
    width: "250px",
  },
  southwest: {
    title: "Assistant GPT",
    logo: SouthwestBlueLogo,
    sidebar_bg_color: "#304cb2",
    send_bg_color: "#304cb2",
    personality: "southwest",
    width: "250px",
  },
  novo_nordisk: {
    title: "Assistant GPT",
    logo: NovoNordiskLogo,
    sidebar_bg_color: "#123262",
    send_bg_color: "#123262",
    personality: "novo_nordisk",
    width: "250px",
  },
  llama2: {
    title: "LLAMA2 Assistant",
    logo: BCGXLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "bcgX",
    width: "250px",
  },
  anthropic: {
    title: "Anthropic Assistant",
    logo: BCGXLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "bcgX",
    width: "250px",
  },
  vertex: {
    title: "Vertex Assistant",
    logo: BCGXLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "bcgX",
    width: "250px",
  },
  vyepti: {
    title: "Assistant GPT",
    logo: vyeptiLogo,
    sidebar_bg_color: "#AA4A44",
    send_bg_color: "#AA4A44",
    personality: "base",
    width: "250px",
  },
  takeda: {
    title: "GenAI MLR Bot Demo",
    logo: takedaLogo,
    sidebar_bg_color: "#bd1309",
    send_bg_color: "#bd1309",
    personality: "takeda",
    width: "250px",
    CitationNumberClass: "text-white-700 bg-red-400",
    CitationDescClass: "mt-4 p-4 shadow-sm bg-white text-red-700 pt-4",
  },
  offsite_one: {
    title: "GenAI Demo Factory",
    logo: BCGXLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "base",
  },
  offsite_two: {
    title: "GenAI Demo Factory",
    logo: BCGXLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "base",
  },
  offsite_three: {
    title: "GenAI Demo Factory",
    logo: BCGXLogo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "base",
  },
  paddy_power: {
    title: ["Paddy the GenAI", "Customer Service Agent"].join("\n"),
    logo: paddyLogo,
    sidebar_bg_color: "#135945",
    send_bg_color: "#135945",
    personality: "paddy",
    width: "300px",
  },
  exelon: {
    title: "GenAI Demo Factory",
    logo: ExelonLogo,
    sidebar_bg_color: "#180d67",
    send_bg_color: "#180d67",
    personality: "base",
    width: "300px",
  },
  omaha: {
    title: "GenAI Demo Factory",
    logo: OmahaLogo,
    sidebar_bg_color: "#F6F6F6",
    send_bg_color: "#1B3864",
    personality: "omaha",
    width: "300px",
  },
  bicester_village: {
    title: ["Google Map Review", "Customer Experience Agent"].join("\n"),
    logo: Bicester_Village_logo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "bicester_village",
    width: "300px",
  },
  procurement: {
    title: "GenAI Procurement Workbench",
    logo: procurement_logo,
    sidebar_bg_color: "#175330",
    send_bg_color: "#175330",
    personality: "procurement",
    width: "300px",
  },
};
