import styled from "styled-components";

interface Props {
  className?: string;
}

export const CitationNumber = styled.div.attrs({
})<Props>`
  className: ${(props) => props.className}; 
  cursor: pointer;
  color: #023e0f;
  font-weight: 900;
  display: inline;
  border-radius: 3px;
  padding: 1px 2px;
  font-size: 0.6em;
  position: relative;
  bottom: 8px;
`;


export const CitationDesc = styled.div.attrs({
})<Props>`
  className: ${(props) => props.className}; 
  border-radius: 8px;
  font-size: 24px;
  padding-top: 24px;

  svg {
    font-size: 1.2em;
    position: relative;
    float: right;
    bottom: 15px;
    cursor: pointer;
  }
`;
