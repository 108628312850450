import styled from "styled-components";

interface Props {
  bgColor?: string;
}

export const AppContainer = styled.div.attrs({
  className: "shadow",
})<Props>`
  background-color: ${(props) => props.bgColor || "#101828"};
  display: grid;
  @media (max-width: 767px) {
    display: block;
    height: 100%;
  }
`;

export const MenuItems = styled.div.attrs({
  className: "menu-items",
})<Props>`
  background-color: ${(props) => props.bgColor || "#101828"};
`;

interface ILogoProps {
  width?: string;
  height?: string;
}

export const Logo = styled.img<ILogoProps>`
  width: ${(props) => props.width || "100%"}!important;
  height: ${(props) => props.height || "auto"}!important;
  margin: auto;
`;
