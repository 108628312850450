import React, { FC, useEffect, useState } from "react";
import "./TermRoom.css";
import TermList from "./TermList";
import { Term } from "common/types";
import LZString from "lz-string";

interface TermboxProps {
    terms: Term[];
    setTerms: React.Dispatch<React.SetStateAction<Term[]>>;

    appName: string;
    id: string;
    collection: string;
    personality: string;
}

let backendURL = 
    process.env.REACT_APP_BACKEND_URL || "http://34.36.104.209/api";



const Termbox: FC<TermboxProps> = ({
    terms,
    setTerms,
    appName,
    id,
    collection,
    personality
}) => {
    const [termsLoading, setLoading] = useState(true);
    useEffect(() => {
        const fetchTerms = async() => {
            let query_params = {
                collection: collection,
                personality: personality,
            };

            let compressedParams = LZString.compressToEncodedURIComponent(
                JSON.stringify(query_params)
            );
            
            const fetchedTerms = await (
                await fetch (`${backendURL}/get_terms?jsonobj=` + compressedParams)
            ).json();

            setTerms(fetchedTerms);
            setLoading(false);
        };

        fetchTerms();
    }, []);

    return(
        <div className="termroom-container">
                {termsLoading ? 'loading...' :  <TermList terms={terms} appName={appName} id={id}/>}
        </div>
    );
}

export default Termbox;
