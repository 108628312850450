import React, { useState, useEffect } from "react";
import "./index.css";
import Chatroom from "../../components/Chatroom";
import { Message } from "../../common/types";
import Upload from "../../components/Upload";
import { useParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import axios from "axios";
import { demos } from "../../common/demoNames";
import { AppContainer, Logo, MenuItems } from "./styled";
import Caveat from "common/caveat";
import DisclaimerModal from "common/disclaimer";
import { BiReset } from "react-icons/bi";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Term } from "../../common/types";
import TermRoom from "../../components/TermRoom";
import SummaryBox from "../../components/SummaryBox";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function HomePage() {
  const MySwal = withReactContent(Swal);
  let { id } = useParams();
  if (id === undefined) id = "";
  const tagManagerArgs = {
    dataLayer: {
      userId: id,
      userProject: "Verse-AI",
      page: "home",
    },
    dataLayerName: "PageDataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);
  let backendURL =
    process.env.REACT_APP_BACKEND_URL || "http://34.36.104.209/api";

  const [messages, setMessages] = useState<Message[]>([]);
  const [terms, setTerms] = useState<Term[]>([]);
  const [summary, setSummary] = useState<string>("");
  const [collection, setCollection] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);
  const [appName] = useState<string>(demos[id].title);
  const [open, setOpen] = useState(
    appName === "GenAI Contract Workbench" ? true : false
  );
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const resetDemo = () => {
    MySwal.fire({
      icon: "warning",
      title: "Reset Demo",
      text: "Resetting this demo will require the data to be re-uploaded.",
      confirmButtonText: "RESET",
      confirmButtonColor: "red",
    }).then(async (result) => {
      if (result.isConfirmed)
        await axios
          .delete(`${backendURL}/reset-demo?collection_name=${collection}`)
          .then((res) => {
            if (res.status === 200) {
              setCollection("");
              MySwal.fire({
                icon: "success",
                title: "Demo Reset Successful",
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              MySwal.fire({
                icon: "error",
                title: "Error resetting the demo, please try again later.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
    });
  };

  useEffect(() => {
    const checkIfUploaded = async (id: string | undefined) => {
      const collection_name = id;
      if (collection_name)
        await axios
          .post(`${backendURL}/is-uploaded`, {
            collection: collection_name,
          })
          .then((res) => {
            if (res.data.is_uploaded) {
              setCollection(collection_name);
            }
          })
          .catch((error) => {
            console.log(error);
          });
    };
    checkIfUploaded(id).catch(console.error);
  }, [backendURL, id]);

  useEffect(() => {
    document.title = appName;
  }, [appName]);

  // useEffect(() => {
  //   setLoading(true);
  // });

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <AppContainer bgColor={demos[id].sidebar_bg_color}>
      <div className="navbar">
        <div className="container nav-container">
          <input className="checkbox" type="checkbox" name="" id="" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <MenuItems bgColor={demos[id].sidebar_bg_color}>
            <div className="sidebar">
              <div className="sidebar-upper">
                <div className="agent-selector">
                  <div className="dropdown-content">
                    {demos[id].logo && (
                      <Logo
                        src={demos[id].logo}
                        alt=""
                        width={demos[id].width}
                        height={demos[id].height}
                      />
                    )}
                    <h3 className={`sidebar-title text-3xl ${id}`}>
                      {appName === "GenAI Contract Workbench" ? (
                        <>
                          {appName}
                        </>
                      ) : (
                        appName
                      )}
                    </h3>
                  </div>
                </div>
                <div className="sessions-section"></div>
              </div>
              <h3 className={`sidebar-title ${id}`}>
                {appName === "GenAI Contract Workbench"}
                {collection && (
                  <BiReset
                    onClick={resetDemo}
                    className={`text-white cursor-pointer text-3xl mt-2 mr-auto ml-auto ${id}`}
                  />
                )}
              </h3>
            </div>
          </MenuItems>
        </div>
      </div>
      <div className="content-container">
        <DisclaimerModal open={open} setOpen={setOpen} />
        {collection ? (
          <>
            <div className="uploadedContent">
              <div className="pdfWindow">
                <Document 
                  file={uploadedFile}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={console.error}
                  >
                 {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  ))}
                </Document>
              </div>
              <div className="rightPanel">
                <div className="rightPanelContent">
                <TermRoom
                  id={id}
                  appName={appName}
                  terms={terms}
                  setTerms={setTerms}
                  collection={collection}
                  personality={demos[id].personality} />
                <SummaryBox
                  summary={summary} 
                  setSummary={setSummary} 
                  appName={appName}
                  id={id}
                  collection={collection}
                  personality={demos[id].personality} />
                <Chatroom
                  id={id}
                  messages={messages}
                  setMessages={setMessages}
                  collection={collection}
                  appName={appName}
                  personality={demos[id].personality} />
                </div>
              </div>
            </div>
            </>
        ) : loading ? (
          <div className="loading-section">
            <img
              style={{ width: 200, height: 170, alignSelf: "center" }}
              src={require("../../gifs/cloud-upload.gif")}
              alt=""
            />
            <p>Ingesting and embedding. Estimated time: 1 minute per file.</p>
          </div>
        ) : (
          <Upload
            id={id}
            setLoading={setLoading}
            setCollection={setCollection}
            setUploadedFile={setUploadedFile}
          />
        )}
      </div>
    </AppContainer>
  );
}
